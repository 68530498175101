<template>
  <div class="map-box">
    <div id="station-map"></div>
    <transition name="strong">
      <div ref="strongmap" v-show="strongshow" class="strong-map text-center">{{ msg }}</div>
    </transition>
    <div class="address-info flex" v-show="address">
      <div class="address-info-l">当前定位:</div>
      <div>
        {{ address }}
        <span>（{{ lngLat.lng }}/{{ lngLat.lat }}）</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'station-map',
    data() {
      return {
        // eslint-disable-next-line
        makerImg: require('@/assets/images/map-marker.png'), // 定位图标
        makerSize: {}, // 标记大小
        markerIcon: {}, // 标记集合
        tempMarker: {}, // 覆盖物
        address: '', // 地址
        lngLat: {}, // 经纬度
        msg: {}, // 提示信息
        strongshow: false, // 提示信息显示隐藏
        duration: 2000, // 弹框消失时间
        // timer: null
      };
    },
    mounted() {
      this.mapInit();
    },
    methods: {
      /**
       * 地图初始化
       */
      mapInit() {
        /* eslint-disable no-undef */
        const map = new AMap.Map('station-map', {
          zoom: 11, // 级别
          viewMode: '2D',
          resizeEnable: true,
        });

        const geocoder = new AMap.Geocoder({ radius: 1000, extensions: 'all' });

        this.makerSize = new AMap.Size(40, 40);
        this.markerIcon = new AMap.Icon({
          size: this.makerSize,
          image: this.makerImg,
          imageSize: this.makerSize,
        });
        // 地图对象
        this.map = map;
        // 设置鼠标的形状
        this.map.setDefaultCursor('grab');
        // 反向解析
        this.geocoder = geocoder;
        map.on('click', e => {
          // 获取当前经纬度
          this.lngLat.lng = e.lnglat.getLng();
          this.lngLat.lat = e.lnglat.getLat();

          this.addMarker(this.lngLat.lng, this.lngLat.lat);
        });
      },
      /**
       * 地址获取坐标
       * cAddress 省市区
       * aAddress 详细地址
       */
      parseAddress(cAddress, dAddress, callback) {
        if (cAddress !== '') {
          if (dAddress !== '') {
            const address = cAddress + dAddress;
            this.geocoder.getLocation(address, (status, result) => {
              if (status === 'complete' && result.info === 'OK') {
                this.lngLat.lng = result.geocodes[0].location.lng;
                this.lngLat.lat = result.geocodes[0].location.lat;
                this.addMarker(this.lngLat.lng, this.lngLat.lat);
                callback(this.lngLat);
              } else {
                this.openHtml('定位失败');
              }
            });
          } else {
            this.openHtml('详细地址不能为空');
          }
        } else {
          this.openHtml('省市区不能为空');
        }
      },
      /**
       * 坐标获取地址
       */
      parseLngLat() {
        this.geocoder.getAddress([this.lngLat.lng, this.lngLat.lat], (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            this.address = result.regeocode.formattedAddress;
            this.lngLat.towncode = result.regeocode.addressComponent.towncode;
            this.lngLat.township = result.regeocode.addressComponent.township;
            this.$emit('mapClick', this.lngLat);
          }
        });
      },
      /**
       * 清除覆盖物
       */
      clearMarker() {
        if (this.tempMarker) {
          this.map.remove(this.tempMarker);
        }
      },
      /**
       * 添加覆盖物
       */
      addMarker(lng, lat) {
        this.lngLat = { lng, lat };

        this.clearMarker();

        this.tempMarker = new AMap.Marker({
          icon: this.markerIcon,
          position: new AMap.LngLat(lng, lat),
          offset: new AMap.Pixel(-18, -38),
        });

        this.map.add(this.tempMarker);
        this.map.panTo(new AMap.LngLat(lng, lat));

        this.parseLngLat();
      },
      /**
       * 异常提示
       */
      openHtml(msg) {
        this.msg = msg;
        this.strongshow = true;
        setTimeout(() => {
          this.strongshow = false;
        }, this.duration);
      },
      /**
       * 返回地址详细信息
       */
      getOpentionObj() {
        return {
          address: this.address,
          lngLat: this.lngLat,
        };
      },
      /**
       * 设置坐标
       */
      setMarker() {
        this.addMarker(this.lngLat.lng, this.lngLat.lat);
      },

      setLngLat(lng, lat) {
        console.log(lng, lat, 'lng, latlng, lat');
        this.lngLat = {
          lng,
          lat,
        };
      },
    },
    // destroyed () {
    //     if (this.timer) {
    //         clearTimeout(this.timer)
    //     }
    // }
  };
</script>
<style lang="scss">
  .map-box {
    position: relative;
    width: 100%;
    height: 100%;

    #station-map {
      width: auto;
      height: 260px;
      background: rgb(252, 249, 242) !important;
    }

    .strong-map {
      position: absolute;
      top: 160.5px;
      right: 0;
      left: 0;
      width: 221px;
      height: 40px;
      margin: auto;
      line-height: 40px;
      color: #fff;
      background: rgba(0, 0, 0, 0.52);
      opacity: 1;
      border-radius: 4px;
    }

    .address-info {
      width: 100%;
      height: auto;
      margin-top: 13px;
      font-size: 12px;

      .address-info-l {
        padding-right: 5px;
      }
    }

    .strong-enter-active,
    .strong-leave-active {
      transition: all 0.3s;
    }

    .strong-enter,
    .strong-leave-to {
      top: 0;
      opacity: 0;
    }

    //高德地图隐藏logo和版本号
    .amap-logo {
      display: none;
    }

    .amap-copyright {
      opacity: 0;
    }
  }
</style>
